<template>
  <v-app-bar
    app
    class="appbar navbar-app"
    clipped-left
    :class="classCordova"
    hide-on-scroll
    :extension-height="68"
    :height="
      applicationConfig && !alreadyShowedNewsBanner
        ? $vuetify.breakpoint.smAndDown
          ? '60'
          : '35'
        : '0'
    "
  >
    <template
      v-if="
        (applicationConfig && !alreadyShowedNewsBanner) ||
          (orderToPay && orderToPay.length > 0 && !hidePendingOrders)
      "
    >
      <template v-if="applicationConfig && !alreadyShowedNewsBanner">
        <category-block
          v-if="applicationConfig && !alreadyShowedNewsBanner"
          :target="applicationConfig"
          position="header"
          class="category-block category-block-header w-100 h-100"
          :container="false"
        />
        <v-btn
          class="close-header-banner-btn close-news"
          icon
          color="default"
          @click="closeBannerNews()"
          :aria-label="$t('common.close')"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
      <div
        v-if="orderToPay && orderToPay.length > 0 && !hidePendingOrders"
        class="uncompleted-orders white--text primary d-flex align-center justify-center w-100 h-100"
      >
        <span class="px-1">
          {{ $t("navbar.uncompletedOrders") }}&nbsp;
          <router-link
            class="white--text text-decoration-none"
            v-for="(orderId, index) in orderToPay"
            :key="orderId"
            :to="{ name: 'Order', params: { orderId: orderId } }"
          >
            {{ orderId
            }}<template v-if="index < orderToPay.length - 1">,&nbsp;</template>
          </router-link>
        </span>
        <v-btn
          class="close-header-banner-btn close-uncompleted-orders"
          icon
          color="default"
          @click="hidePendingOrders = true"
          :aria-label="$t('common.close')"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </div>
    </template>
    <template v-slot:extension>
      <!-- FIXED CONTENT -->
      <v-row no-gutters :align="'center'">
        <v-col
          cols="1"
          class="d-flex justify-center align-center"
          v-if="$routerHistory.hasPrevious() && $routerHistory.path != '/'"
        >
          <v-btn
            v-if="$routerHistory.hasPrevious()"
            @click.prevent.stop="historyBack()"
            aria-label="Torna alla pagina precedente"
            class="router-back"
            title="Torna alla pagina precedente"
            icon
          >
            <v-icon class="default--text back-arrow">$chevronLeft</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2" sm="1" class="d-flex justify-center align-center">
          <router-link
            :to="{
              name: 'Home'
            }"
          >
            <img
              src="/logo/logo_mobile.png"
              height="51"
              width="51"
              alt="Logo coop trentino"
            />
          </router-link>
        </v-col>
        <v-col
          :cols="$routerHistory.hasPrevious() ? 8 : 9"
          :sm="$routerHistory.hasPrevious() ? 9 : 10"
          class="d-flex flex-row justify-center align-center px-md-2 "
          style="flex-wrap: nowrap;"
        >
          <div
            style="min-width: 100px; max-width: 100%;"
            class="flex-grow-1 flex-shrink-0 pl-2"
          >
            <SearchInput />
          </div>
        </v-col>
        <v-col cols="1" @click.prevent.stop="drawerLinks = !drawerLinks">
          <v-icon
            large
            color="primary"
            class="justify-end"
            style="float: right;"
            >$verticalDots</v-icon
          >
        </v-col>
      </v-row>

      <v-progress-linear
        :active="showOverlay"
        :indeterminate="showOverlay"
        absolute
        bottom
        color="primary"
        class="mobile-progress"
        aria-label="Caricamento in corso"
      ></v-progress-linear>
    </template>
  </v-app-bar>
</template>
<style lang="scss">
.shopping-cart-icon {
  color: var(--v-primary-base) !important;
}

@media screen and (max-width: 1360px) {
  .username {
    display: none !important;
  }
}

.v-main {
  background-color: white;
}

.v-app-bar--fixed {
  background-color: #fcfcfc !important;
  z-index: 11 !important;
  width: 100%;
  // iOS 11.0 fixes
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-app-bar--fixed {
    z-index: 11 !important;
    width: 100%;
  }
}

.drawer-btn {
  height: 60px !important;
  width: 60px !important;
  label {
    font-size: 9px;
  }
}

.back-arrow {
  font-size: 20px !important;
  height: 20px !important;
  width: 20px !important;
}

.router-back {
  text-decoration: none;
}

.router-back,
.noop {
  .theme--light.v-btn:before {
    background: white !important;
  }
}
.mobile-progress {
  bottom: -4px !important;
}
</style>
<script>
//TODO rivedere completamente la gestione dei breakpoint in modo che non si scassino su telefono....
import SearchInput from "@/components/navigation/SearchInput.vue";

import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";

import { mapActions, mapState } from "vuex";

export default {
  name: "Navbar",
  components: { SearchInput },
  mixins: [login, cartInfo],
  props: {
    applicationConfig: { type: Object, required: false }
  },
  data() {
    return {
      bannerTopKey: 0,
      hidePendingOrders: false
    };
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerRight: "app/updatedDrawerRight",
      updatedDrawerLinks: "app/updatedDrawerLinks",
      updateOrderToPay: "custom/updateOrderToPay"
    }),
    goBack() {
      this.$router.go(-1);
    },
    closeBannerNews() {
      if (this.applicationConfig.categoryBlocks) {
        let headerNews = this.applicationConfig.categoryBlocks.find(
          block => block.templateBlock.codInt == "header"
        );
        if (headerNews) {
          let bannersTopShowingDate = {};
          bannersTopShowingDate[headerNews.categoryBlockId] = new Date(
            Date.now() + 3600 * 1000 * 24
          ).toISOString();
          window.sessionStorage["bannersTopShowingDate"] = JSON.stringify(
            bannersTopShowingDate
          );
        }
      }
      this.bannerTopKey++;
    },
    openCart() {
      if (this.editableCart) {
        if (this.isAuthenticated) {
          if (this.$vuetify.breakpoint.xs && this.editableCart) {
            this.$router.push("/checkout");
          } else {
            this.drawerRight = !this.drawerRight;
          }
        } else {
          this.doLogin("OpenCart");
        }
      } else {
        this.handleLogin();
      }
    },
    async handleLogin() {
      if (await this.needLogin("navbar")) {
        if (await this.needAddress()) {
          await this.needTimeslot();
        }
      }
    },
    historyBack() {
      window.history.back();
    }
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      storeDrawerLinks: state => state.app.drawerLinks,
      cart: ({ cart }) => cart.cart,
      orderToPay: state => state.custom.orderToPay
    }),
    drawerLinks: {
      get() {
        return this.storeDrawerLinks;
      },
      set(value) {
        this.updatedDrawerLinks(value);
      }
    },
    alreadyShowedNewsBanner() {
      this.bannerTopKey;
      if (this.applicationConfig.categoryBlocks) {
        let headerNews = this.applicationConfig.categoryBlocks.find(
          block => block.templateBlock.codInt == "header"
        );
        if (headerNews) {
          let bannersTopShowingDate = window.sessionStorage[
            "bannersTopShowingDate"
          ]
            ? JSON.parse(window.sessionStorage["bannersTopShowingDate"])
            : {};
          let catBlockId = headerNews.categoryBlockId;

          let nextOpeningDate = bannersTopShowingDate[catBlockId];
          let today = new Date();
          if (
            Object.prototype.hasOwnProperty.call(
              bannersTopShowingDate,
              catBlockId
            ) &&
            Date.parse(nextOpeningDate) > Date.parse(today)
          )
            return true;
        } else {
          // there is no proposal -> do not show news banner
          return true;
        }
      }
      return false;
    },
    enableRegistration() {
      return global.config.registration;
    },
    totalItems() {
      return this.cart.totalItems;
    },
    editableCart() {
      return !(this.$route.name == "Payment");
    },
    showOverlay() {
      return this.$store.state.app.pendingRequests.count > 0;
    },
    classCordova: function() {
      return {
        "is-cordova": typeof cordova !== "undefined"
      };
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    }
  },
  watch: {
    isAuthenticated(val, oldVal) {
      if (val != oldVal) {
        if (val == true) {
          this.updateOrderToPay();
        } else {
          this.updateOrderToPay([]);
        }
      }
    }
  }
};
</script>
