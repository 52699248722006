var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{staticClass:"appbar navbar-app",class:_vm.classCordova,attrs:{"app":"","clipped-left":"","hide-on-scroll":"","extension-height":68,"height":_vm.applicationConfig && !_vm.alreadyShowedNewsBanner
      ? _vm.$vuetify.breakpoint.smAndDown
        ? '60'
        : '35'
      : '0'},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-row',{attrs:{"no-gutters":"","align":'center'}},[(_vm.$routerHistory.hasPrevious() && _vm.$routerHistory.path != '/')?_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"1"}},[(_vm.$routerHistory.hasPrevious())?_c('v-btn',{staticClass:"router-back",attrs:{"aria-label":"Torna alla pagina precedente","title":"Torna alla pagina precedente","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.historyBack()}}},[_c('v-icon',{staticClass:"default--text back-arrow"},[_vm._v("$chevronLeft")])],1):_vm._e()],1):_vm._e(),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"2","sm":"1"}},[_c('router-link',{attrs:{"to":{
            name: 'Home'
          }}},[_c('img',{attrs:{"src":"/logo/logo_mobile.png","height":"51","width":"51","alt":"Logo coop trentino"}})])],1),_c('v-col',{staticClass:"d-flex flex-row justify-center align-center px-md-2",staticStyle:{"flex-wrap":"nowrap"},attrs:{"cols":_vm.$routerHistory.hasPrevious() ? 8 : 9,"sm":_vm.$routerHistory.hasPrevious() ? 9 : 10}},[_c('div',{staticClass:"flex-grow-1 flex-shrink-0 pl-2",staticStyle:{"min-width":"100px","max-width":"100%"}},[_c('SearchInput')],1)]),_c('v-col',{attrs:{"cols":"1"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.drawerLinks = !_vm.drawerLinks}}},[_c('v-icon',{staticClass:"justify-end",staticStyle:{"float":"right"},attrs:{"large":"","color":"primary"}},[_vm._v("$verticalDots")])],1)],1),_c('v-progress-linear',{staticClass:"mobile-progress",attrs:{"active":_vm.showOverlay,"indeterminate":_vm.showOverlay,"absolute":"","bottom":"","color":"primary","aria-label":"Caricamento in corso"}})]},proxy:true}])},[(
      (_vm.applicationConfig && !_vm.alreadyShowedNewsBanner) ||
        (_vm.orderToPay && _vm.orderToPay.length > 0 && !_vm.hidePendingOrders)
    )?[(_vm.applicationConfig && !_vm.alreadyShowedNewsBanner)?[(_vm.applicationConfig && !_vm.alreadyShowedNewsBanner)?_c('category-block',{staticClass:"category-block category-block-header w-100 h-100",attrs:{"target":_vm.applicationConfig,"position":"header","container":false}}):_vm._e(),_c('v-btn',{staticClass:"close-header-banner-btn close-news",attrs:{"icon":"","color":"default","aria-label":_vm.$t('common.close')},on:{"click":function($event){return _vm.closeBannerNews()}}},[_c('v-icon',[_vm._v("$close")])],1)]:_vm._e(),(_vm.orderToPay && _vm.orderToPay.length > 0 && !_vm.hidePendingOrders)?_c('div',{staticClass:"uncompleted-orders white--text primary d-flex align-center justify-center w-100 h-100"},[_c('span',{staticClass:"px-1"},[_vm._v(" "+_vm._s(_vm.$t("navbar.uncompletedOrders"))+"  "),_vm._l((_vm.orderToPay),function(orderId,index){return _c('router-link',{key:orderId,staticClass:"white--text text-decoration-none",attrs:{"to":{ name: 'Order', params: { orderId: orderId } }}},[_vm._v(" "+_vm._s(orderId)),(index < _vm.orderToPay.length - 1)?[_vm._v(", ")]:_vm._e()],2)})],2),_c('v-btn',{staticClass:"close-header-banner-btn close-uncompleted-orders",attrs:{"icon":"","color":"default","aria-label":_vm.$t('common.close')},on:{"click":function($event){_vm.hidePendingOrders = true}}},[_c('v-icon',[_vm._v("$close")])],1)],1):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }